
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import PaymentsRepository from "@/app/repository/payments.repository";
export default {
  name: "Payment",
  data: () => ({}),
  setup() {
    const router = useRouter();
    const store = useStore();
    const showModal = ref(true);
    const urlIframe = ref(null);

    if (store.getters["userStore/getUserID"] == null) {
      router.replace({ name: "Quiz_email" });
    }

    const replaceRoute = () => {
      showModal.value = false;
      setTimeout(() => {
        router.replace({ name: "Checkout" });
      }, 500);
    };
    const planSelected = computed(
      () => store.getters["plansSubscriptionsStore/getPlan"]
    );

    const userID = store.getters["userStore/getUserID"];

    PaymentsRepository.getIframePayment(userID, planSelected.value._id)
      .then((result) => {
        urlIframe.value = result.iframeLink;
      })
      .catch((error) => {
        console.log(error);
      });

    return { showModal, replaceRoute, planSelected, urlIframe };
  },
};
